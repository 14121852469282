import React from "react";
import { SocialIcon } from "react-social-icons";
import "./Home.css";
import Contact from "./Contact.js";
import Footer from "./Footer.js";

const Home = () => {
  return (
    <div>
      <section className="flex items-center sm:py-0 py-20 sm:min-h-[70vh] gap-5 lg:gap-10">
        <div className="flex flex-col gap-3 w-full lg:w-2/3">
          <h1 className="text-4xl sm:text-5xl leading-[43.2px] sm:leading-[57.6px] font-bold">
            Hey there, <br /> I'm Chisom Winifred.
          </h1>
          <p className="text-sm">
            I’m a digital marketer, content creator, and copywriter with 4+
            years of experience crafting compelling copy and engaging brand
            content. Alongside my marketing and content creation roles, I have a
            strong editorial background, having served as Managing Editor at The
            Lincoln Review. There, I honed my expertise in editing fiction,
            creative nonfiction, and poetry, overseeing the development of
            diverse, high-quality literary content. In addition, as Editor at
            Trendupp Africa Magazine and Beauty Editor at Glam Africa Magazine,
            I further developed my skills in commissioning, editing, and
            curating content across lifestyle, memoir, and pop culture genres.
            With a passion for storytelling and precision, I excel at creating
            content that captivates audiences and drives meaningful engagement.
          </p>
          {/* <div className="web__btn__grp">
            <NavLink to="/portfolio">
              <button className="work__btn">My Work ⚒</button>
            </NavLink>
            {/* <NavLink to="/blog">
                <button className="work__btn btn">Blog 📝</button>
              </NavLink> 
          </div> */}
          <h4 className="text-base flex gap-2 font-medium">
            Check out my
            <SocialIcon
              className="hover:-translate-y-2 duration-500"
              url="https://instagram.com/chisomwinifred"
              target="_blank"
              fgColor="#f1f1f1"
              bgColor="#333"
              style={{ height: 30, width: 30 }}
            />
            <SocialIcon
              className="hover:-translate-y-2 duration-500"
              url="https://www.linkedin.com/in/chisom-winifred-6a2baa164"
              target="_blank"
              fgColor="#f1f1f1"
              bgColor="#333"
              style={{ height: 30, width: 30 }}
            />
          </h4>
        </div>
        {/* <div className="w-1/2 justify-center hidden lg:flex">
          <div class="hero__img" data-aos="fade-left">
            <div class="img-container"></div>
          </div>
        </div> */}
      </section>
      {/* <About /> */}
      {/* <WhatIDo /> */}
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;
