import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import "./NavBar.css";

const COLORS = {
  primaryDark: "#141414",
  primaryLight: "#5C5C5C",
};

const MenuLabel = styled.label`
  background: linear-gradient(
    45deg,
    ${COLORS.primaryDark},
    ${COLORS.primaryLight}
  );
  position: fixed;
  top: 15px;
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0 1rem 3rem rgba(182, 237, 200, 0.3);
  text-align: center;
`;

const NavBackground = styled.div`
  position: fixed;
  top: 20px;
  right: 4rem;
  background-image: linear-gradient(
    45deg,
    ${COLORS.primaryDark},
    ${COLORS.primaryLight}
  );
  text-align: center;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  z-index: 600;
  transform: ${(props) => (props.clicked ? "scale(80)" : "scale(0)")};
  transition: transform 0.8s;
`;

const Icon = styled.span`
  position: relative;
  background-color: ${(props) => (props.clicked ? "transparent" : "#f1f1f1")};
  width: 1.2rem;
  height: 2px;
  display: inline-block;
  margin-top: 20px;
  transition: all 0.3s;
  &::before,
  &::after {
    content: "";
    background-color: #f1f1f1;
    width: 1.2rem;
    height: 2px;
    display: inline-block;
    position: absolute;
    left: 0;
    transition: all 0.3s;
  }
  &::before {
    top: ${(props) => (props.clicked ? "0" : "-0.6rem")};
    transform: ${(props) => (props.clicked ? "rotate(135deg)" : "rotate(0)")};
  }
  &::after {
    top: ${(props) => (props.clicked ? "0" : "0.6rem")};
    transform: ${(props) => (props.clicked ? "rotate(-135deg)" : "rotate(0)")};
  }
  ${MenuLabel}:hover &::before {
    top: ${(props) => (props.clicked ? "0" : "-1rem")};
  }
  ${MenuLabel}:hover &::after {
    top: ${(props) => (props.clicked ? "0" : "1rem")};
  }
`;

const Navigation = styled.nav`
  height: 100vh;
  position: fixed;
  top: 0;
  text-align: center;
  right: 0;
  z-index: 600;
  width: ${(props) => (props.clicked ? "100%" : "0")};
  opacity: ${(props) => (props.clicked ? "1" : "0")};
  transition: width 0.8s, opacity 0.8s;
`;

const List = styled.ul`
  position: absolute;
  list-style: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
`;
const ItemLink = styled(NavLink)`
  display: inline-block;
  font-size: 2rem;
  font-weight: 300;
  text-align: center;
  text-decoration: none;
  color: #f1f1f1;
  padding: 1rem 2rem;
  background-image: linear-gradient(
    120deg,
    transparent 0%,
    transparent 50%,
    #f1f1f1 50%
  );
  background-size: 240%;
  transition: all 0.4s;
  &:hover,
  &:active {
    background-position: 100%;
    color: ${COLORS.primaryDark};
    transform: translateX(1rem);
  }
`;

function NavBar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  return (
    <div className="nav sticky top-0 w-full py-4 border-b z-50 bg-white shadow-sm">
      <h1 className="text-3xl logo font-black">Chisom.</h1>
      <MenuLabel
        className="sm:!right-[4.3rem] right-4"
        htmlFor="navi-toggle"
        onClick={handleClick}
      >
        <Icon clicked={click}>&nbsp;</Icon>
      </MenuLabel>
      <NavBackground clicked={click}>&nbsp;</NavBackground>

      <Navigation clicked={click} className="NavBar">
        <List>
          <li>
            <ItemLink onClick={handleClick} to="/">
              Home
            </ItemLink>
          </li>
          <li>
            <ItemLink onClick={handleClick} to="/bio">
              Bio
            </ItemLink>
          </li>
          {/* <li>
            <ItemLink onClick={handleClick} to="/what-i-do">
              What I Do
            </ItemLink>
          </li> */}
          <li>
            <ItemLink onClick={handleClick} to="/portfolio">
              Portfolio
            </ItemLink>
          </li>
          {/* <li>
            <ItemLink onClick={handleClick} to="/blog">
              Blog
            </ItemLink>
          </li> */}
        </List>
      </Navigation>
    </div>
  );
}

export default NavBar;
