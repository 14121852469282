import React from "react";
import Contact from "./Contact.js";
import Footer from "./Footer.js";
import "./Portfolio.css";
import { portfolioItems } from "./data.js";

const Portfolio = () => {
  return (
    <div>
      <section id="portfolio" className="py-20">
        <div className="text-center flex flex-col items-center gap-2 max-w-[400px] mx-auto mb-8">
          <h1 className="font-extrabold text-2xl">My Portfolio</h1>
          <p className="text-sm">
            Featured below are a few articles, projects and campaigns I've
            written copy and created content for.
          </p>
          <div class="w-[120px] bg-brand-500 rounded-full h-[3px] mt-2"></div>
        </div>
        <div
          class="row"
          className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5"
          data-aos="fade-up"
        >
          {portfolioItems.map((item) => (
            <a
              target="_blank"
              className="bg-white border border-slate-100 py-4 hover:-translate-y-2 duration-500 hover:shadow-xl !shadow-brand-200/50"
              href={item.url}
              rel="noreferrer"
            >
              <div class="flex flex-col gap-3 p-4">
                <item.icon className="text-3xl text-brand-700" />
                <h2>{item.title}</h2>
                <a
                  //   className="underline"
                  arget="_blank"
                  href={item.url}
                  rel="noreferrer"
                  className="hover:underline pb-2 duration-700 text-sm"
                >
                  Link
                </a>
              </div>
            </a>
          ))}
        </div>
      </section>
      <Contact />
      <Footer />
    </div>
  );
};

export default Portfolio;
