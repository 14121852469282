import React from "react";

const Footer = () => {
  return (
    <div>
      <section className="py-4 bg-brand-100/20">
        <footer>
          <h1 className="text-3xl font-black">Chisom.</h1>
          <p className="text-sm">
            Copyright &copy; {new Date().getFullYear()} Chisom Winifred.
          </p>
        </footer>
      </section>
    </div>
  );
};

export default Footer;
