import Access from "../portfolio/Access Pan African Awards.pdf";
import Amstel from "../portfolio/Amstel Malta Article.pdf";
import EnuguFood from "../portfolio/Enugu Food Meets Fashion.pdf";
import Fidelity from "../portfolio/Fidelity corporate banking.pdf";
import Mtn from "../portfolio/MTN Article.pdf";
import Personal from "../portfolio/Personal statement.pdf";
import MediaMoney from "../portfolio/THE MEDIA MONEY OF LAGOS.pdf";
import ViacomCBS from "../portfolio/Thought leadership.pdf";
import Trendupp from "../portfolio/Trendupp Awards Official Release.pdf";
import Trophy from "../portfolio/Trophy Episode 7.pdf";
import BookReview from "../portfolio/Book Review_ Writer's Postcards (edited).pdf";
import { PiBookOpenTextThin } from "react-icons/pi";
import { LiaMicrophoneSolid, LiaPenAltSolid } from "react-icons/lia";
import { ImNewspaper } from "react-icons/im";

export const portfolioItems = [
  {
    title: "Featured writer on Bellanaija.",
    url: "https://www.bellanaija.com/author/chisom-winifred",
    icon: LiaPenAltSolid,
  },
  {
    title: "Book Review",
    url: BookReview,
    icon: PiBookOpenTextThin,
  },
  {
    title: "Beauty editor at Glam Africa magazine.",
    url: "https://glamafrica.com/author/chisom-winifred",
    icon: LiaPenAltSolid,
  },
  {
    title:
      "Featured article for Nigerian Multinational Commercial Bank, Access Bank PLC.",
    url: Access,
    icon: ImNewspaper,
  },
  {
    title:
      "Featured article for Fidelity Bank Plc, a commercial bank in Nigeria.",
    url: Fidelity,
    icon: ImNewspaper,
  },
  {
    title: "Thought leadership article for ViacomCBS Networks Africa.",
    url: ViacomCBS,
    icon: ImNewspaper,
  },
  {
    title: "Promotion Article for Trophy Extra Special Band Season 2.",
    url: Trophy,
    icon: ImNewspaper,
  },
  {
    title: "Personal statement for international chef Soliat Bada.",
    url: Personal,
    icon: LiaMicrophoneSolid,
  },
  {
    title:
      "Editor for the first edition of Enugu Food Meets Fashion Magazine sponsored by Golden Penny Foods and Milo.",
    url: EnuguFood,
    icon: LiaPenAltSolid,
  },
  {
    title: "Speech for group CEO RED Media Africa Adebola Williams.",
    url: MediaMoney,
    icon: LiaMicrophoneSolid,
  },
  {
    title:
      "Press article for the maiden edition of Trendupp Africa; Nigeria’s First Influencers & Content Creators Award.",
    url: Trendupp,
    icon: ImNewspaper,
  },
  {
    title: "Press article for MTN Nigeria.",
    url: Mtn,
    icon: ImNewspaper,
  },
  {
    title: "Press article for international malt brand, Amstel Malta.",
    url: Amstel,
    icon: ImNewspaper,
  },
  {
    title: "Sales article for London based beauty brand, Loveshea skincare.",
    url: "https://www.hayat.media/loveshea-skincare-has-all-you-need-for-healthy-youthful-skin/",
    icon: ImNewspaper,
  },
  {
    title: "Sales article for London based beauty brand, Mia Lueur.",
    url: "https://www.hayat.media/glow-and-grow-with-skincare-products-from-mia-lueur-organic-support/",
    icon: ImNewspaper,
  },
  {
    title:
      "Blog post for Nigeria's Leading Real Estate Group Geohomes Housing.",
    url: "https://geohomesgroup.com/content/33-are-you-a-real-estate-artisan-heres-how-geohomes-can-ensure-your-next-payday",
    icon: ImNewspaper,
  },
  {
    title:
      "Blog post for Nigeria's Leading Real Estate Group Geohomes Housing.",
    url: "https://geohomesgroup.com/content/34-investing-in-properties-the-smart-way",
    icon: ImNewspaper,
  },
];
