import React from "react";
import "./Contact.css";

const Contact = () => {
  return (
    <div>
      <section id="contact" className="pb-10 mt-10">
        <div className="flex flex-col gap-2 mb-3">
          <h1 className="text-3xl font-bold">Work With Me</h1>
          <p className="text-sm">
            Have a product? Let’s amplify its reach. Have opportunities to
            discuss? I’m all ears. Just want to say hello? Click the button
            below.
          </p>
        </div>
        <a href="mailto:chisomwinnifred.cw@gmail.com">
          <button className="bg-brand-700/80 text-gray-200 px-2 hover:bg-brand-800 duration-500 py-2">
            Let's Discuss 📩
          </button>
        </a>
      </section>
    </div>
  );
};

export default Contact;
