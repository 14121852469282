import React from "react";
import chisom from "../images/chisom.jpeg";
import "./About.css";
import Contact from "./Contact";
import Footer from "./Footer";

const About = () => {
  return (
    <div>
      <section className="bg-brand-100/20 py-10" id="about">
        <div className="head__text flex flex-col items-center mb-6">
          <h1 className="font-extrabold text-2xl">Bio</h1>
          <div className="w-[40px] bg-brand-500 rounded-full h-[2px]"></div>
        </div>
        <div className="lg:flex-row flex flex-col items-center gap-6 justify-between mt-8">
          <div
            style={{
              boxShadow: "3px 3px 20px 5px #0000001c",
            }}
            className="w-1/2 mx-auto mb-10 lg:mb-0 lg:w-full"
            data-aos="flip-left"
          >
            <img src={chisom} alt="" />
          </div>
          <div className="w-full flex flex-col gap-6" data-aos="fade-up">
            <div className="flex flex-col gap-1">
              <h2 className="font-semibold text-lg">
                Digital Marketer, Content Creator, Copywriter, and Editor
              </h2>
              <p className="text-brand-600 text-sm">
                I am Chisom Winifred Asogwa, a digital marketer, content
                creator, copywriter, and editor with a strong editorial
                background. I currently serve as Managing Editor at{" "}
                <strong>The Lincoln Review,</strong> where I edit fiction,
                creative nonfiction, and poetry, ensuring high editorial
                standards across a range of literary genres. I’m also the Editor
                at <strong>Trendupp Africa Magazine,</strong> the first magazine
                focusing exclusively on influencers and content creators in
                Africa, where I oversee content that explores the dynamic
                digital landscape on the continent. <br />
                <br />
                In addition to my editorial roles, I have published a book
                review of <strong>Writer’s Postcards</strong> (Penguin Random
                House SEA, 2023), by Dipika Mukherjee, in{" "}
                <strong>The Lincoln Review TLR,</strong> an international
                literary journal published annually by the Creative Writing
                Programme at the University of Lincoln. I made history as the
                first Master’s student to be published in the journal since its
                inception, a milestone I’m proud to have achieved.
                <br />
                <br />I hold an MA in Creative Writing from the University of
                Lincoln, where my passion for storytelling and editorial work
                has only deepened. I am committed to creating engaging,
                impactful content across various platforms, and I strive to
                continue delivering narratives that inspire, connect, and
                captivate audiences.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Contact />
      <Footer />
    </div>
  );
};

export default About;
