import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Portfolio from "./components/Portfolio";
import SinglePost from "./components/SinglePost";
import Post from "./components/Post";
import About from "./components/About";
import WhatIDo from "./components/WhatIDo";
import NavBar from "./components/NavBar";
import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<About />} path="/bio" />
        <Route element={<WhatIDo />} path="/what-i-do" />
        <Route element={<Portfolio />} path="/portfolio" />
        <Route element={<SinglePost />} path="/post:slug" />
        <Route element={<Post />} path="/blog" />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
